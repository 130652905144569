

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: whitesmoke;
    border-radius: 6px;
  }
  
  .storeTypeButton1, .storeTypeButton2 {
    position: absolute !important;
    left: 320px !important;
    color: white !important;
  }
  
  
  
  .searchProduct {
    position: absolute !important;
    width: 300px !important;
    height: 32px !important;
    top: 256px !important;
    left: 255px !important;
    opacity: 1 !important;
    padding-right: 8px !important;
    font-size: 12px !important;
    background: #00000000 !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: #565e6cff !important;
    outline: none !important;
    color: #424955ff !important;
    border-color: #9095a0ff !important;
  }
  
  .filter {
    position: absolute !important;
  
    top: 253px;
    left: 570px !important;
    background-color: whitesmoke !important;
    border-radius: 6px !important;
    height: 36px !important;
  }


  .bulkMapperDisabled {
    cursor: not-allowed !important;
    border-color: #d9d9d9 !important;
    color: rgba(0,0,0,.25) !important;
    background-color: rgba(0,0,0,.04) !important;
    box-shadow: none !important;
  }
  
  /*.productTable {*/
  /*  position: absolute;*/
  /*    top: 330px;*/
  /*    left: 240px;*/
  /*    width: 80%;*/
  /*    padding: 0 5px;*/
  /*    background: #ffffffff;*/
  /*    border-radius: 6px;*/
  /*    border-width: 1px;*/
  /*    border-color: #dee1e6ff;*/
  /*    */
  /*}*/
  
  .ant-table-tbody > tr > td {
    padding: 6px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .ant-table-thead > tr > th{
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    text-align: left !important;
    padding-left: 15px !important;
  }
  .ant-table-thead > tr > th:nth-child(1) {
    text-align: left !important;    
  }


.pagination_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.pagination_wrap  .pagination_text{
  text-align: center;
  padding:0 10px;
  min-width: 80px;
}
.pagination_wrap button{
  width:24px;
  height:24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  border:2px solid #F0F0F0;
  background: #fff;
  border-radius: 4px;
  margin:0 5px;
}
.pagination_wrap button svg{
  height:18px;
}

.status-label {
  cursor: initial;
  background: #cdf7df !important;
  color: #444;
  padding: 0;
  height: 30px;
  padding: 0px 18px;
  width: auto;
  min-width: auto;
  font-size: 16px;
  font-weight: 500;
}
.status-label.not-synced{
  background:#ffecd3!important;
}
.status-label.not-synced:hover{
  background: #ffecd3 !important;
  color: #444 !important;
}
.status-label:hover{
  background: #cdf7df !important;
  color: #444 !important;
}

.position {
  display: flex;
}
.carousel-ui {
  width: 300px;
}
.contentStyle {
  margin: 0;
  width: 529px;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  color: #fff;
  line-height: 160px;
  text-align: center;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: #000;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: #000;
}

.refills {
  margin-top: -25px;
  margin-left: 50px;
  width: 100%;
}

.key-value-pair {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .key {
    font-weight: bold;
    flex: 1;
    text-align: left;
  }

  .dot {
    margin: 0 5px;
  }

  .value {
    flex: 1;
    text-align: left;
    margin-left: 180px;
  }
}

.ant-drawer-close {
  position: absolute;
  right: 0;
}
.ant-drawer .ant-drawer-header {
  padding-top: 0px;
  padding-bottom: 0px;
}

.product_search_container{
  padding-left: 20px;
  margin-top: 20px;

}
.product_search_inner{
  display: flex;
  align-items: center;
  width: 100%;
}

.product_search_inner .custom-input{
  width: 100%;
}

.product_status_drop .ant-select .ant-select-selector{
  border: 2px solid #E0E1E5!important;
  background: #ffffff!important;

}

.product_status_drop .ant-select-selection-placeholder{
  font-size: 15px!important;
}

.heading1{
  font-size: 32px;
  line-height: normal;
  padding-bottom: 15px;

}

.product_variant_outer .ant-spin-container{
  height: initial!important;
  max-height: 300px;
  overflow: scroll;

}
.description-inner-container{
  max-height: 400px;
  overflow-x: scroll;
  overflow-y: scroll;
}

.product-heading{
  font-size: 32px;
  line-height: normal;
  margin-bottom: 15px;
  margin-top: 15px;

}