
.order-dropdown-outer{
    display: flex;
    align-items: center;
}
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 60%;
    left: 5%;
    /*margin-left: -60px;*/
    padding: 10px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    /*position: absolute;*/
    /*top: 100%;*/
    /*left: 50%;*/
    /*margin-left: -5px;*/
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}


.custom-input1{
    display: inline-block;
    background-color: whitesmoke;
    border: 1px solid #E0E1E5;
    height: 32px;
    border-radius: 6px;
    padding: 0px 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    outline: none;
    line-height: 21px;
    color: #444444;

}
.custom-input-1::placeholder {
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    font-size: 18px;
    color: #C7C7C7;
}
.custom-input-1:focus-visible {
    box-shadow: none;
    outline: none!important;
    border-color: #96999f;
}
.custom-input-1:focus {
    box-shadow: none;
    border-color: #96999f;
}

.order_inner-container{
    margin-bottom: 25px;

}
.custom-input2{
    outline: none!important;
    border-color: #96999F!important;
}

.custom-input2:where(.css-dev-only-do-not-override-1ij74fp).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #96999F;

}
.custom-datepicker-order .rs-input-group.rs-input-group-inside {
   height: 32px;
    background-color: whitesmoke;
}

.custom-datepicker-order .rs-input-group.rs-input-group-inside .rs-date-range-input{
    background-color: whitesmoke!important;

}