
  
.profileContent{
  background-color: whitesmoke;
}

  .accountCard{
    position: absolute;
    top: 380px;
    left: -114px;
    width: 668px; 
    height: 220px; 
    background: #FFFFFFFF; 
    border-radius: 6px; 
    box-shadow: 0px 0px 1px
  }
  
  .name{
    display: flex;
  }



  /*.profile_inner_wrapper .settings{*/
  /*  width:calc(100% - 350px);*/
  /*  padding-left: 30px;*/
  /*}*/
.profile_inner_wrapper .ant-tabs-nav {
  display: none !important;
}

