.site-layout-background {
    background: rgb(255, 255, 255);
  }
  
.ant-layout-sider-items{
    background-color: whitesmoke;
  }
  
  .ant-menu{
  background-color: whitesmoke;
  }
  .ant-layout{
    background-color: white;
  }

  .logOutButton{
    overflow: auto;
    margin-left: 1.5em;
    
    
    

  }
  .side-navigation{
    z-index:99;
    width: 220px;
  }

.side-navigation .ant-menu{
  border-right:2px solid #F0F0F0 !important;
  background: #fff !important;
  padding-top: 27px;
}
.side-navigation .ant-menu li .ant-menu-title-content{
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 17px;
  color: #444;
  display: flex;
  align-items: center;
  transition: all .2s;
}
.side-navigation .ant-menu li:hover .ant-menu-title-content{
  color:#dc5a4b !important;
}
/*.side-navigation .ant-menu li a:before{*/
/*  display: none;*/
/*}*/
.side-navigation .ant-menu li span svg{
  fill:#444 !important;
  transition: all .2s;
  height: 18px !important;
  width: 18px !important;
}
.side-navigation .ant-menu li:hover span svg{
  fill:#dc5a4b !important;
}
.side-navigation .ant-menu li:hover,
.side-navigation .ant-menu li.ant-menu-item-selected{
  background: transparent !important;
}
.side-navigation .ant-menu li.ant-menu-item-selected span svg{
  fill:#dc5a4b !important;
}
.side-navigation .ant-menu li.ant-menu-item-selected .ant-menu-title-content{
  color:#dc5a4b !important;
}
