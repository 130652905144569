
.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: white;
  }
  .ant-layout-content{
    background-color: rgb(255, 255, 255);
  }
  .ant-layout-header{
    background: white;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 2px;
    
  }
  .logo {
    float: left;
    width: 120px;
    height: 31px;
    
    
  }
  
  .logo > h2 {
    /* Text 2945 */
    position: absolute;
  top: 7px;
  width: 176px;
  font-size: 32px;
  line-height: 48px;
  color: #171a1fff;
  
  }
  
  .userProfile{
    float: right;
    padding-right: 10px;
  }
  
  .dropDownButton{
    background: transparent;
    border: none !important;
      
  }
  .ant-row-rtl .logo {
    float: right;
    
  }
    [data-theme="light"] .site-layout-content {
      background: #141414;
    }
    .site-layout-background {
      background: rgb(255, 255, 255);
    }
    .ant-layout-sider-items{
      background-color: whitesmoke;
    }
    
    .ant-menu{
    background-color: whitesmoke;
    }
    .ant-layout{
      background-color: white;
    }
  
    .logOutButton{
      overflow: auto;
      margin-left: 1.5em;
      
      
      
  
    }
  
    /* .heading{
        position: absolute;
        top: 80px;
        left: 250px;
        width: 896px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
          Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 28px;
        line-height: 48px;
        color: #171a1fff; 
    } */