.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: white;
  }
  .ant-layout-content {
    background-color: rgb(255, 255, 255);
  }

  /*.heading {*/
  /*  position: absolute !important;*/
  /*  top: 55px;*/
  /*  left: 242px;*/
  /*  width: 896px;*/
  /*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,*/
  /*    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;*/
  /*  font-size: 24px;*/
  /*  line-height: 48px;*/
  /*  color: #171a1fff;*/
  /*}*/
  

  
.search {
    position: absolute !important;
    width: 350px !important;
    height: 32px !important;
    top: 156px !important;
    left: 240px !important;
    opacity: 1 !important;
    padding-right: 8px !important;
    font-size: 12px !important;
    background: #00000000 !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: #565e6cff !important;
    outline: none !important;
    color: #424955ff !important;
    border-color: #9095a0ff !important;
  }
  
  .filter {
    position: absolute;
    top: 153px;
    left: 505px;
    background-color: whitesmoke;
    border-radius: 6px;
    height: 36px;
  }
  
  .addStore {
    /*position: absolute;*/
    /*top: 125px;*/
    /*left: 85%;*/
    /*width: 120px;*/
    /*height: 33px;*/
    /*padding: 0 12px;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,*/
    /*  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;*/
    /*font-size: 14px;*/
    /*line-height: 22px;*/
    /*color: #005289ff;*/
    /*background: #99d6ffff;*/
    /*opacity: 1;*/
    /*border: none;*/
    /*border-radius: 6px;*/
    /*gap: 6px;*/
  }
  
  .hometable {
    /*position: absolute;*/
    /*top: 230px;*/
    /*left: 240px;*/
    /*width: 80%;*/
    /*padding: 0 5px;*/
    /*background: #ffffffff;*/
    /*border-radius: 6px;*/
    /*border-width: 1px;*/
    /*border-color: #dee1e6ff;*/
    /*border: solid rgba(0, 0, 0, 0.05);*/
  }
  
  .ant-table-tbody > tr > td {
    padding: 6px !important;
    text-align: center !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  }
  .ant-table-thead > tr > th{
    color: rgba(0, 0, 0, 0.6) !important;
    text-align: center !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen ,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
    
  }
