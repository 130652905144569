

@font-face {
        font-family: 'itc_souvenir_stdlight';
        src: url('../fonts/ITC_Souvenir/souvenirstd-light-webfont.woff2') format('woff2'), url('../fonts/ITC_Souvenir/souvenirstd-light-webfont.woff') format('woff');
        font-weight: 300;
        font-style: normal;
}

@font-face {
        font-family: 'itc_souvenir_stdlight_italic';
        src: url('../fonts/ITC_Souvenir/souvenirstd-lightitalic-webfont.woff2') format('woff2'), url('../fonts/ITC_Souvenir/souvenirstd-lightitalic-webfont.woff') format('woff');
        font-weight: 300;
        font-style: italic;
}

@font-face {
        font-family: 'itc_souvenir_stdmedium';
        src: url('../fonts/ITC_Souvenir/souvenirstd-medium-webfont.woff2') format('woff2'), url('../fonts/ITC_Souvenir/souvenirstd-medium-webfont.woff') format('woff');
        font-weight: 500;
        font-style: normal;
}


.ant-input-affix-wrapper >input.ant-input:-webkit-autofill,
.ant-input-affix-wrapper >input.ant-input:-webkit-autofill:hover,
.ant-input-affix-wrapper >input.ant-input:-webkit-autofill:focus,
.ant-input-affix-wrapper >input.ant-input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
       border: none !important;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-input-affix-wrapper:not(:where(.css-dev-only-do-not-override-1ij74fp).ant-input-affix-wrapper-disabled):hover{
        border:2px solid #E0E1E5 !important;
}

.signin_inner_wrapper .productTitle{
        margin:0 0 24px;
        padding:0;
}
.ant-spin-nested-loading, .ant-spin-container{
        min-height: 100%;
}
main.ant-layout-content .ant-spin-nested-loading{
        min-height: auto;
        height: auto;
}

.ant-spin-container{
        height:100vh;
}
.signin_wrapper .ant-card-body{
padding: 32px 40px;
}
.signin_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
}
.signin_inner_wrapper{
        width:100%
}

.white_bg{
        border-radius: 12px;
        background: #FFF;
        box-shadow: 16px 24px 40px 0px rgba(16, 24, 40, 0.25);
        width: 100%;
        max-width: 500px !important;
        margin: auto;
}

.subheading{
        font-style: normal;
        font-weight: 300;
        font-size: 2rem;
        line-height: 38px;
        color: #444;
        font-family: 'itc_souvenir_stdlight';
        margin-bottom: 22px;
        margin-top:0;
}

.primary_btn:disabled, .ant-btn-primary:disabled{
        background-color: rgb(248, 222, 219) !important;
        box-shadow: none;
        color: rgb(255, 255, 255) !important;
        border-color: transparent;
        outline: none;
}
.primary_btn:disabled:hover, .ant-btn-primary:disabled:hover{
        background-color: rgb(248, 222, 219) !important;
        box-shadow: none;
        color: rgb(255, 255, 255) !important;
        border-color: transparent;
        outline: none;
}
.primary_btn, .ant-btn-primary{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #dc5a4b;
        border: none;
        border-radius: 34px;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 21px;
        color: #fff;
        padding: 0 25px;
        height: 42px;
        min-width: 125px;
        text-align: center;
        transition: .2s all ease;
        -webkit-appearance: none;
        border: none;
        box-shadow: none;
}
        .primary_btn:hover, .ant-btn-primary:hover{
                background-color: #e37b6f !important;
                color: #fff !important;
        }

.text_btn{
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        font-style: normal;
        font-weight: 500;
        letter-spacing: .2px;
        font-size: 1rem;
        line-height: 19px;
        color: #dc5a4b;
        background: transparent;
        border: none;
}
.text_btn:hover{
        color:#e37b6f !important;
}

.ant-modal-footer .ant-btn-default, .ant-btn-default1{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        cursor: pointer;
        border: none;
        border-radius: 34px;
        font-weight: 400;
        border: 2px solid #DC5A4B;
        font-size: 1.125rem;
        line-height: 21px;
        color: #dc5a4b;
        padding: 0 25px;
        height: 42px;
        min-width: 125px;
        text-align: center;
        transition: .2s all ease;
}
.ant-modal-footer .ant-btn-default:hover, .ant-btn-default1:hover{
       border-color: #e37b6f !important;
        color:#e37b6f !important;
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td{
        background: #fff !important;
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-checkbox-checked .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1ij74fp).ant-checkbox-indeterminate .ant-checkbox-inner:after{
        background: #5592a0;
        border-color: #5592a0;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1ij74fp).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
        border-color: #5592a0;
}



        .login-form-forgot{
                display: inline-flex;
                vertical-align: middle;
                font-style: normal;
                font-weight: 400;
                font-size: .9375rem;
                line-height: 18px;
                color: #dc5a4b;
        }
        .signUpLink{
                color:#444;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
        }
.signUpLink a{
        color:#5592A0;
        text-decoration: underline;
}
.custom-select span + span+div{
        min-height: 50px;
        border-width: 2px;
        border-color:#E0E1E5 !important;
        outline: none !important;
        box-shadow: none !important;
}
.custom-input{
        display:inline-flex;
        width: 100%;
        background-color: #fff;
        border: 2px solid #E0E1E5;
        height: 50px;
        border-radius: 4px;
        padding: 0 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        color: #444;
        outline: none;
}
.custom-input::placeholder{
        font-style: normal;
        font-weight: 400;
        font-size:18px;
        line-height: 21px;
        color:#C7C7C7;
}
.custom-input:focus{
        box-shadow: none !important;
        border-color: #96999F !important;
}
.custom-input:hover{
        box-shadow: none !important;
        border-color: #E0E1E5 !important;
        border-width:2px !important;
}
.custom-input .ant-input-prefix{
        display: none;
}


@media (max-height:650px){
        .signin_wrapper{
                align-items: flex-start;
                padding: 40px 0;
        }
        .white_bg{
                margin-bottom: 80px;
        }
}



.ant-layout-content .ant-spin-container{
        min-height: auto;
        height: auto;
}




/* home css start here */
main.ant-layout-content{
        padding: 100px 30px 0 270px !important;
        position: relative;
}

.content_head_ui{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
}
.heading{
        font-weight: 300;
        font-style: normal;
        font-size: 2.5rem;
        line-height: 44px;
        color: #444;
        font-family: 'itc_souvenir_stdlight';
        margin-top:0;
        margin-bottom: 0;
}


.custom-table-wrap table .ant-table-thead th{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #444 !important;
        padding: 0 7px 10px;
        text-align: left !important;
        vertical-align: top;
        background: transparent;
}
.custom-table-wrap table .ant-table-thead th:before{
        display: none;
}

.custom-table-wrap table .ant-table-tbody td{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 20px;
        color: #444 !important;
        padding: 20px 7px;
        text-align: left !important;
        background: transparent;
}
.custom-table-wrap table .ant-table-tbody td p a{
        font-weight: 500;
        font-size: 1rem;
        line-height: 20px;
        color: #444 !important;
}

.custom-table-wrap .ant-table-pagination{
        justify-content: center !important;
}
.ant-tag-orange{
        background: rgba(250,197,94,.3);
        border-radius: 4px;
        color: #444;
        padding: 5px 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        border: none !important;
}
.ant-tag-green{
        background:rgba(0, 199, 16, 0.2);
        border-radius: 4px;
        color: #444;
        padding: 5px 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        border: none !important;
}

.anticon-delete svg, .anticon-copy svg{
        fill:#444 !important;
        transition:0.2s ease-in-out;
}

.anticon-copy svg path{
        fill:#444 !important;
}
.anticon-copy:hover svg path, .anticon-delete:hover svg{
        fill:#dc5a4b !important;
}
.anticon-copy svg path:first-child{
        fill:#fff !important;
}
.anticon-copy:hover svg path::first-child{
        fill:#fff !important;
}

.ant-pagination li.ant-pagination-item-active{
        background: #DC5A4B;
        color: #fff !important;
        border: 1px solid #DC5A4B !important;
        transition: all .2s;
}
.ant-pagination li.ant-pagination-item-active a{
        color:#fff !important;
}
.ant-pagination li.ant-pagination-item-active:hover{
        border: 1px solid #DC5A4B;
}
.ant-pagination li{
        min-width: 32px;
        height: 32px;
        padding: 0 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E0E1E5 !important;;
        border-radius: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: #96999f;
}
.ant-pagination li.ant-pagination-prev,
.ant-pagination li.ant-pagination-next{
        border: none !important;;
        background: none;
}

.ant-modal{
        max-height: 704px;
        overflow-y: auto;
        padding: 0;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        position: relative;
        box-shadow: 16px 24px 40px #10182840;
        width:100%;
        max-width: 550px;
}
.ant-modal .ant-modal-content{
        padding: 40px 45px 40px;
}
.ant-modal .ant-modal-title{
        font-style: normal;
        font-weight: 700;
        font-size: 1.625rem;
        line-height: 30px;
        color: #444;
        margin-bottom: 30px;
        text-align: left !important;
}
.form-label{
        font-weight: 500;
        font-size: .9375rem;
        line-height: 18px;
        color: #444;
        margin-bottom: 7px;
        display: flex;
}
.ant-input{

}
.ant-modal-footer{
        margin-top: 30px !important;
        text-align: center !important;
}

.delete-confirmation-wrapper .ant-modal-title{
        margin-bottom: 20px;
}
.ant-modal-body .desc{
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 24px;
        color: rgb(102, 112, 120);
}

.store_dropdown_wrap{
 position: relative;
        margin-bottom: 40px;

}
.store_dropdown_wrap h3{
        font-size:20px;
        font-weight: 500;
        margin-bottom: 10px;
        min-height: 32px;
}

p.empty_wrapper {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
}
.custom-form-wrap div{
        width:100%;
}
.ant-modal-body .ant-form-item-label{
        padding: 0;
}
.ant-modal-body .ant-form-item-label label{
        font-weight: 500;
        font-size: .9375rem;
        line-height: 18px;
        color: #444;
        margin-bottom: 7px;
        display: flex;
}
.w-100{
 width:100%;
}
.w-50{
        width:50%;
}

.source-order-list{
        display: flex;
        flex-wrap: wrap;
}
.source-order-list .source-text{
        padding-right: 8px;
}
.source-order-list .source-text:last-child span{
       display: none;
}



/* profile page ui */

main.ant-layout-content.profile_wrapper{
        padding: 100px 80px 30px 80px !important;
}


.profile_left_card{
        border-radius: 4px;
        border: 1px solid #EAECF0;
        background: #FFF;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.07), 0px 12px 16px -4px rgba(16, 24, 40, 0.12);
        width: 350px;
}

.profile_inner_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
}
.profile_inner_wrapper .settings {
        width: calc(100% - 350px);
        display: flex;
        flex-direction: column;
        padding-left: 30px;
}

.profile_image-blk{
        background: #f1f1f1;
}

.profile_inner_wrapper  .ant-divider {
        display: none;
}

.profile_inner_wrapper .heading{
        margin-bottom: 16px;
}
.profile_inner_wrapper .ant-card-body{
        min-height: 345px;
}
.profile_inner_wrapper .profile_left_card .ant-card-body{
        min-height:406px;
        width:100%;
}
.profile_inner_wrapper .ant-form-item-label{
        font-weight: 500;
        font-size: .9375rem;
        line-height: 18px;
        color: #444;
        margin-bottom: 7px;
        display: flex;
}
.ant-typography{
        font-weight: 500;
        font-style: normal;
        font-size: 18px;
        color: #444;
        margin-top: 0;
        margin-bottom: 0;
}

.profile-block{
        text-align: center;
}

.custom-file-upload{
        border: 1px solid #dc5a4b;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 34px;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 21px;
        color: #dc5a4b;
        padding: 0 25px;
        height: 42px;
        min-width: 125px;
        text-align: center;
        transition: .2s all ease;
        -webkit-appearance: none;
        box-shadow: none;
}

.profileCard {
        border: 1px solid #EAECF0;
        background: #FFF;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.07), 0px 12px 16px -4px rgba(16, 24, 40, 0.12);
}

.store_dropdown_wrap .top-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
}
.store_dropdown_wrap .dropdown-wrapper{
        width: 50%;
}
.store_dropdown_wrap .dropdown-wrapper:first-child{
        padding-right: 20px;
}
.store_dropdown_wrap .dropdown-wrapper:last-child{
        padding-left: 20px;
}

.button-wrap {
        display: flex;
        align-items: center;
        padding-top: 20px;
}

.button-wrap .ant-btn-primary{
    margin-left: 10px;
}
.product-table .ant-table-tbody > tr {
      cursor: pointer;

}

.product-table .ant-table-tbody > tr > td{
        padding: 16px 7px !important;

}

.product-table .ant-table-tbody > tr > td:nth-child(1){
  padding: 16px 7px !important;
}

.shop_page_container{
        border-radius: 12px;
        background: #FFF;
        box-shadow: 16px 24px 40px 0px rgba(16, 24, 40, 0.25);
        width: 100%;
        max-width:600px !important;
        margin: auto;
        padding: 32px 40px;
        border: 1px solid #f0f0f0;
}
.shop_page_container .subheading{
        font-size:28px;
}
.storepage_wrapper{
        display: flex;
        justify-content: space-between;
}
.storepage_wrapper .field_wrap{
        margin-bottom: 24px;
        box-shadow: none;
}
.storepage_wrapper .field_wrap .ant-card-head{
        padding: 0;
        border: none;
}
.storepage_wrapper .field_wrap  .ant-card-body{
        padding: 0;
}

.storepage_wrapper .select-type-card {
        position: relative;
        margin-bottom:0px;
        width:42%;
}
.storepage_wrapper .select-type-card:last-child{
        margin-bottom: 0;
}
.storepage_wrapper .ant-col:after {
        position: absolute;
        content: "";
        width: 1px;
        height: 80%;
        background: #f1f1f1;
        right: 1px;
        top: 50%;
        transform: translateY(-50%);
}
.storepage_wrapper .ant-col:last-child:after{
        display: none;
}

.storepage_wrapper .spacer{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 6%;
}
.storepage_wrapper .spacer:after{
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        background: #e0e1e5;
}
.storepage_wrapper .spacer span{
        position: absolute;
        margin: auto;
        padding: 0 10px;
        background: #fff;
        z-index: 1;
        color: #e0e1e5;
        font-size: 16px;
}

.card_detail_wrapper{
}
.home_content_wrap ul{
        display: flex;
        align-items: flex-start;
        padding: 0;
        justify-content: space-between;
}
.home_content_wrap ul li{
        width:32%;
        background: #FFFFFF;
        border: 1px solid #EAECF0;
        box-shadow: 0 4px 4px #1018280f, 0 1px 3px #1018281a;
        border-radius: 4px;
        display: inline-block;
        padding: 22px 30px 25px;
        position: relative;
}
.home_content_wrap ul li label{
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 22px;
        color: #444;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
}
.home_content_wrap ul li p{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: #444;
        word-break: break-word;
}
.store_sync_wrap {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #EAECF0;
        box-shadow: 0 4px 4px #1018280f, 0 1px 3px #1018281a;
        border-radius: 4px;
        display: inline-block;
        padding: 22px 30px 25px;
        position: relative;
        margin-top: 30px;
}
.store_sync_wrap label{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #444;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
}

.store_sync_wrap p{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #444;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
}
.select-type-card .ant-btn-primary{
        width: 100%;
}

.product_status_btn{
        border-radius: 8px;
        margin-right: 10px;
}
.product_status_btn .status_icons {
        margin-left: 10px;

}
.product_status_btn:hover .status_icons svg path {
        stroke: #e37b6f;

}
.product_status_btn:focus .status_icons svg path {
        stroke: #e37b6f;

}


/*.custom-input1{*/
/*        display: inline-block;*/
/*        background-color: whitesmoke;*/
/*        border: 1px solid #E0E1E5;*/
/*        height: 32px;*/
/*        border-radius: 6px;*/
/*        padding: 0px 14px;*/
/*        font-style: normal;*/
/*        font-weight: 400;*/
/*        font-size: 14px;*/
/*        line-height: 21px;*/
/*        color: #444444;*/

/*}*/
/*.custom-input-1::placeholder {*/
/*        font-style: normal;*/
/*        font-weight: 400;*/
/*        line-height: 21px;*/
/*        font-size: 18px;*/
/*        color: #C7C7C7;*/
/*}*/
/*.custom-input-1:focus-visible {*/
/*        box-shadow: none;*/
/*        outline: none!important;*/
/*        border-color: #96999f;*/
/*}*/
/*.custom-input-1:focus {*/
/*        box-shadow: none;*/
/*        border-color: #96999f;*/
/*}*/

/*.order_inner-container{*/
/*        margin-bottom: 25px;*/

/*}*/
.apply_btn_wrapper{
        display: flex;
        align-items: center;
        justify-content: center;

}
.apply_btn{
        height: 32px;
        /*margin-left: 5px;*/
        font-size: 16px;
        margin-top: 5px;
}


.status_dropdown{
        padding: 15px 4px!important;

}